import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { Header, HomeProfileSection } from "components";
import Home from "pages/home";
import { routePaths } from "routes";
import { getProfile } from "utilities";
import { ApplicationContext } from "shared";

const Dashboard = ({ subComponent: ChildComponent, selectedId }) => {
  const {getTheUpdatedData} = useContext(ApplicationContext);
  const [navMenuList, setNavMenuList] = useState([
    {
      id: 1,
      name: `Home`,
      navigationScreen: routePaths.home,
      isSelected: true,
    },
    {
      id: 2,
      name: `Manage Account`,
      navigationScreen: routePaths.manageAccount,
      isSelected: false,
    },
    {
      id: 3,
      name: `Locations`,
      navigationScreen: routePaths.locations,
      isSelected: false,
    },
  ]);

  useEffect(() => {
    getTheUpdatedData();
  },[])

  useEffect(() => {
    const tempArray = [];
    navMenuList.map((item) => {
      tempArray.push({
        ...item,
        isSelected: item.id == selectedId,
      });
    });
    setNavMenuList(tempArray);
  }, [selectedId]);

  return (
    <div className="container" style={{overflowX: 'hidden'}}>
      <Header navMenuList={navMenuList} />
      <ChildComponent />
    </div>
  );
};

export default Dashboard;
