import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import {
  HomeProfileSection,
  HomeCategorySection,
  AddItemPopup,
  TranslatePopup,
  InfoCard,
  AddDocumentPopup,
  GenericModal,
} from "components";
import { CATEGORIES_TYPES, ENUMS, constants, selectedStripeKey } from "common";
import { images } from "common/images";
import { getDashboardStats } from "services";
import {
  deleteItem,
  getAllProfileRecords,
  getDashboardDocumentStats,
  saveUserData,
  updateUserData,
  updateUserReOrderData,
} from "services/firebaseServices";
import { ApplicationContext } from "shared";
import {
  getProfile,
  getSecondaryProfile,
  setSecondaryProfile,
} from "utilities";
import Stripe from "stripe";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { MedicalCard } from "../../components/medical-card";

export const formatDate = (date) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDateString = date?.toLocaleDateString("en-GB", options);
  return formattedDateString;
};

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardOrderBtn, setCardOrderBtn] = useState(false);
  const [cardReorderBtn, setCardReOrderBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { categoryListing, setCategoryListing, userData, setUserData } =
    useContext(ApplicationContext);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [showTranslate, setShowTranslate] = useState(false);
  const { cardWithLock } = images;
  const [showModal, setShowModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemDescription, setItemDescription] = useState({
    type: "",
    description: "",
  });

  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: "",
    body: "",
  });

  useEffect(() => {
    fetchRecords(true);
    setUserData(getSecondaryProfile());
    if(localStorage.getItem('welcome')){
      setShowWelcomeModal(true)
    }
  }, []);

  useEffect(() => {
    const tempData = {
      ...userData,
      cardReOrderStatus: "Registered",
      cardORerderDate: new Date()?.toISOString(),
    };
    if (searchParams?.get("reorder_status")) {
      saveDataToFirebase(tempData, true);
      fetchData(tempData)
    }
  }, [searchParams]);

  const fetchRecords = async (isToFetchAll) => {
    try {
      setIsLoading(true);
      const tempData = [...categoryListing];
      if(isToFetchAll){
        tempData[0].dataListing = await getDashboardStats(
          CATEGORIES_TYPES.diagnoses
        );
        tempData[1].dataListing = await getDashboardStats(
          CATEGORIES_TYPES.medicines
        );
        tempData[2].dataListing = await getDashboardStats(
          CATEGORIES_TYPES.allergies
        );
        tempData[3].dataListing = await getDashboardStats(
          CATEGORIES_TYPES.vaccines
        );
        tempData[4].dataListing = await getDashboardDocumentStats(
          CATEGORIES_TYPES.documents
        );
      }else {
        let selectedIndex = tempData.findIndex((item => item.type == selectedType));
        if(selectedIndex == 4){
          tempData[selectedIndex].dataListing = await getDashboardDocumentStats(
            selectedType
          );
        }else {
          tempData[selectedIndex].dataListing = await getDashboardStats(
            selectedType
          );
        }
      }
      setIsLoading(false);
      setCategoryListing(tempData);
    }
    catch(err) {
      console.log(err)
    }
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setShowDocuments(true);
  };

  const getTheUpdatedList = (currentType) => {
    setIsLoading(true);
    let dataString = `These are my ${currentType} => `;
    const currentDataListing = categoryListing.filter(
      (item) => item.type == currentType
    );
    currentDataListing[0]?.dataListing?.map((item, index) => {
      dataString = dataString.concat(`${index + 1} ${item?.description} \n`);
    });
    setIsLoading(false);
    return dataString;
  };

  const reOrderCardWithStripe = async () => {
    setCardReOrderBtn(true);
    console.log("reorder")
    setSecondaryProfile({
      ...userData,
      cardReOrderStatus: "Registered",
      cardORerderDate: formatDate(new Date()),
    });
    let selectedCurr = "EUR";
    let amount = 499.00;
    try {
      const result = await fetch('https://open.er-api.com/v6/latest/EUR');
      const currencies = await result.json();
      const myCurrency = await fetch('https://ipapi.co/currency');
      const code = await myCurrency.text();
      selectedCurr = code;
      amount = Math.ceil(currencies.rates[code]*499);
      // console.log(selectedCurr)
    } catch(err) {
      selectedCurr = "EUR";
      amount = 499.00;
    }
    // console.log(selectedCurr.substring(0,2))
    const stripe = new Stripe(selectedStripeKey);
    try {
      const params = {
        mode: "payment",
        payment_method_types: ["card"],
        line_items: [
          {
            price_data: {
              currency: selectedCurr,
              product_data: {
                name: "Personal Safety",
              },
              unit_amount_decimal: amount+"",
            },
            adjustable_quantity: {
              enabled: false,
            },
            quantity: 1,
          },
        ],
        // billing_address_collection: 'none',
        // shipping_address_collection: {
        //   allowed_countries: [selectedCurr.substring(0,2)], 
        // },
        allow_promotion_codes: true,
        success_url: `${constants.siteBaseURL}/?reorder_status=${true}`,
        cancel_url: `${constants.siteBaseURL}/`,
      };
      const session = await stripe.checkout.sessions.create(params);
      if (session?.url) {
        window.location.assign(session?.url);
      }
    } catch (err) {
      // console.log("Error creating checkout session", err);
    }
  };
  const orderCard = async () => {
    setCardOrderBtn(true);
    setSecondaryProfile({
      ...userData,
      cardOrderStatus: "Registered",
      cardOrderDate: formatDate(new Date()),
    });
    const tempData = {
      ...userData,
      cardOrderStatus: "Registered",
      cardOrderDate: formatDate(new Date()),
    };
    fetchData(tempData);
  };
  const fetchData = async (data) => {
    const getData = await getProfile();
    if (getData) {
      try {
        const response = await axios.get(
          `https://wmcpdfgenerator20231027032332.azurewebsites.net/PdfGenerator/Get?userId=${getData.uid}`
        );

        if (response.status == 200) {
          saveDataToFirebase(data);
        } else {
          console.error("Error fetching PDF data:", response.statusText);
        }
      } catch (error) {
        saveDataToFirebase(data);
        console.error("Error fetching PDF data:", error.message);
      }
    }
  };
  const saveDataToFirebase = async (tempData, reOrder) => {
    const getProfileData = await getProfile();
    if (getProfileData) {
      let response;
      if (reOrder) {
        response = await updateUserReOrderData(getProfileData?.uid, tempData);
      } else {
        response = await updateUserData(getProfileData?.uid, tempData);
      }
      searchParams.delete("reorder_status");
      setSearchParams(searchParams);
      setCardOrderBtn(false);
      setCardReOrderBtn(false);
      if (response) {
        let msg = "Ordered";
        if (searchParams?.get("reorder_status")) {
          msg = "ReOrdered";
        }
        setApiResponse({
          type: ENUMS.MODAL_TYPES.SUCCESS,
          title: "Success",
          body: `Your Card hass been ${msg} successfully`,
        });
        setShowModal(true);
      }
    }
  };
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1980,
        margin: 'auto',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 80,
        paddingBottom: 80,
      }}
    >
      <HomeProfileSection userData={userData} />
      {categoryListing.map(
        ({ mainTitle, type, background, icon, dataListing }, index) => {
          return (
            <HomeCategorySection
              key={index}
              isLoading={isLoading}
              mainTitle={mainTitle}
              type={type}
              selectedType={selectedType}
              setSelectedType={(type) => setSelectedType(type)}
              icon={icon}
              background={background}
              dataListing={dataListing}
              refreshStats={() => {
                fetchRecords(false);
              }}
              setApiResponse={setApiResponse}
              setShowConfirmationModal={setShowConfirmationModal}
              setItemDescription={setItemDescription}
              onAddButtonClicked={() => {
                if (type !== CATEGORIES_TYPES.documents) {
                  setShowAdd(true);
                } else {
                  openFilePicker();
                  // setShowDocuments(true)
                }
                setSelectedType(type);
              }}
              onTranslateButtonClicked={() => {
                setShowTranslate(true);
                setSelectedType(type);
              }}
              onShareButtonClicked={() => {
                setSelectedType(type);
                window.open(
                  `mailto:?subject=My ${type}&body=${getTheUpdatedList(type)}`
                );
              }}
            />
          );
        }
      )}
      <div className="cardSectionContainer">
        {
        // userData?.cardOrderStatus == "" ? (
        //   <div className="card-cont">
        //     <div className="cardContainer">
        //       <img
        //         src={cardWithLock}
        //         height="auto"
        //         width="100%"
        //         alt="no-image"
        //       />
        //     </div>
        //   </div>
        // ) : (
          // <div className="cardContainer-bg">
          //   <div className="card-with-lock">
          //     <div>
          //       <span className="card-without-lock-item-title">{"Name"}</span>
          //       <p className="card-name">
          //         {`${userData?.firstName} ${userData?.lastName}`}
          //       </p>
          //       <div className="card-without-item-cont">
          //         <CardWithoutLockItem
          //           title={"National ID No."}
          //           value={userData?.ssn}
          //         />
          //         <CardWithoutLockItem
          //           title={"Date of Birth"}
          //           value={`${new Date(
          //             userData.birthDate
          //           ).toLocaleDateString()}`}
          //         />
          //         <CardWithoutLockItem
          //           title={"Issued on"}
          //           value={`${new Date(
          //             userData.cardOrderDate
          //           ).toLocaleDateString()}`}
          //         />
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <div className="home-card">
            <MedicalCard {...userData} emergencyNumber={userData.emergencyContact?.[0]?.phone} policyNumber={userData?.insurance?.[0]?.policy} 
              insuranceCompany={userData?.insurance?.[0]?.company} />
          </div>
          
        // )
        }

        <div className="cardContainer">
          <InfoCard
            loading={cardOrderBtn}
            userData={userData}
            payable={false}
            onOrderPress={() => {
              orderCard();
            }}
          />
        </div>
        <div className="cardContainer">
          <InfoCard
            loading={cardReorderBtn}
            userData={userData}
            payable={true}
            onReOrderPress={() => {
              reOrderCardWithStripe();
            }}
          />
        </div>
      </div>

      {showAdd && (
        <AddItemPopup
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          type={selectedType}
          refreshStats={() => {
            fetchRecords(false);
          }}
          mainTitle={selectedType}
        />
      )}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      {showDocuments && (
        <AddDocumentPopup
          showAdd={true}
          setShowAdd={setShowDocuments}
          type={selectedType}
          file={file}
          refreshStats={() => {
            fetchRecords(false);
          }}
          mainTitle={selectedType}
        />
      )}
      {showTranslate && (
        <TranslatePopup
          showAdd={showTranslate}
          setShowAdd={setShowTranslate}
          type={selectedType}
          mainTitle={selectedType}
        />
      )}
      <GenericModal
        show={showModal}
        type={apiResponse.type}
        title={apiResponse.title}
        body={apiResponse.body}
        buttonText={constants.ok}
        handleClose={() => {
          setShowModal(false);
          window.location.reload();
        }}
      />
      <GenericModal
        show={showWelcomeModal}
        type={ENUMS.MODAL_TYPES.INFO}
        title={'Thank you for becoming a member at World Medical Card. '}
        body={`It’s important that you remember to fill in your medical data, so you can start using our services.`}
        buttonText={constants.ok}
        handleClose={() => {
          setShowWelcomeModal(false);
          localStorage.removeItem("welcome");
        }}
      />
      <GenericModal
        show={showConfirmationModal}
        type={apiResponse.type}
        title={apiResponse.title}
        body={apiResponse.body}
        buttonText={constants.ok}
        button2
        buttonText2={constants.cancel}
        primaryBtnPress={async () => {
          setShowConfirmationModal(false);
          await deleteItem(itemDescription.type, itemDescription.description);
          fetchRecords();
        }}
        secondaryBtnPress={() => setShowConfirmationModal(false)}
      />
    </div>
  );
};

const CardWithoutLockItem = ({ title, value }) => {
  return (
    <div>
      <span className="card-without-lock-item-title">{title}</span>
      <p className="card-without-lock-item-value">{value}</p>
    </div>
  );
};

export default Home;
