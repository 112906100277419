import React, { useEffect, useRef, useState } from "react";
import { images } from "common/images";
import "./style.scss";

export const MedicalCard = (data) => {
    
    const { firstName, lastName, birthDate, organDonation, emergencyNumber, nationality, insuranceCompany, policyNumber, ssn } = data
    const preRef = useRef(null);
    const [isBack, setBack] = useState(false)
    
    useEffect(() => {
        const pre = preRef.current;
        const rotateElement = (event) => {
            const x = event.clientX;
            const y = event.clientY;
            const middleX = window.innerWidth / 2;
            const middleY = window.innerHeight / 2;
            const offsetX = ((x - middleX) / middleX) * 45;
            const offsetY = ((y - middleY) / middleY) * 45;
            if (pre) {
                pre.style.setProperty("--rotateX", `${isBack? (180 - offsetX) : offsetX}deg`);
                pre.style.setProperty("--rotateY", `${-1 * offsetY}deg`);
            }
        };
        document.addEventListener("mousemove", rotateElement);
        return () => {
            document.removeEventListener("mousemove", rotateElement);
        };
    }, [isBack]); // Empty dependency array ensures that the effect runs only once on component mount

    const flipCard = function() {
        const pre = preRef.current;
        setBack(val => !val);
        setTimeout(()=>{
            pre.style.setProperty("--rotateX", `${!isBack ? 0 : 180}deg`);
        })
    }

    return (
        <div className="medical-card-container">
            <div ref={preRef} className="medical-card" onClick={()=> flipCard()}>
                <div className="front-side">
                    <div className="card-side top-info">Medical Information Inside</div>
                    <div className="card-side bottom-info">Emergency Medical Card</div>
                    <div className="card-side left-info">Medical Card</div>
                    <div className="card-side right-info">Break Seal to Open</div>
                    <div className="break-border"></div>
                    <div className="inside">
                        <div className="logo-info">
                            <div className="logo-left">
                                <span className="ask">Issued on</span>
                                <span className="val">wmc-card.biz</span>
                            </div>
                            <div className="logo-right">
                                <img src={images.wmcLogo} height="auto" width="100px" alt="no-image" style={{ borderRadius: 0 }} />
                            </div>
                        </div>
                        <div className="card-data">
                            <div className="card-desc">
                                <span className="ask">Name</span>
                                { firstName ? <span className="reply">{firstName} {lastName}</span> : <span className="reply">Your Name Here</span>}
                            </div>
                            <div className="card-desc">
                                <span className="ask">Travel Insurance Company</span>
                                <span className="val">{insuranceCompany}</span>
                            </div>
                            <div className="card-desc-data">
                                <div className="card-desc">
                                    <span className="ask">Policy No:</span>
                                    <span className="val">{policyNumber}</span>
                                </div>
                                <div className="card-desc">
                                    <span className="ask">Emergency Phone No:</span>
                                    <span className="val">{emergencyNumber}</span>
                                </div>
                            </div>
                            <div className="card-desc-data">
                                <div className="card-desc">
                                    <span className="ask">National ID number or similar:</span>
                                    <span className="val">{ssn}</span>
                                </div>
                                <div className="card-desc">
                                    <span className="ask">Nationality</span>
                                    <span className="val">{nationality}</span>
                                </div>
                            </div>
                            <div className="card-desc-data">
                                <div className="card-desc">
                                    <span className="ask">Date of Birth:</span>
                                    <span className="val">{birthDate}</span>
                                </div>
                                <div className="card-desc">
                                    <span className="ask">Organ Donation</span>
                                    <span className="val">{organDonation ? 'Yes' : 'No'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back-side">
                    <div className="card-side top-info">Emergency Medical Card</div>
                    <div className="card-side bottom-info">Medical Information Inside</div>
                    <div className="card-side left-info">Break Seal to Open</div>
                    <div className="card-side right-info">Medical Card</div>
                    <div className="break-border"></div>
                    <div className="inside"><img src='/images/back-card.png' height="auto" alt="no-image" style={{ borderRadius: 0 }} /></div>
                </div>
            </div>
        </div>
    );
};
